import React, { useEffect, useState } from "react";
import Breadcrumbs, { Breadcrumb } from "components/layouts/Breadcrumb";
import {
  Button,
  Col,
  Form,
  Row,
  Image,
  Table,
  Layout,
  Card,
  Typography,
  Modal,
  Input,
  Spin,
} from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  fetchListPlace,
  getListCityByPrefectureData,
  getStateData,
  ListPlaceRequest,
  listPlaceSelector,
  PlaceData,
  setStatusPlace,
} from "redux/slice/listPlaceSlice";
import { isValidRoles, useRedirectStatusError } from "lib/utils";
import { Helmet } from "react-helmet";
import placeImg from "assets/images/place/ic-add-place.svg";
import iconFilter from "assets/images/filter.svg";
import { Constant, SUPER_ADMIN } from "config/constant";
import { selectAuth } from "redux/slice/authSlice";
import {
  fetchPlaceStatistic,
  fetchPlaceType,
  placeSelector,
  resetUpdateState,
} from "redux/slice/placeSlice";
import { useTranslation } from "react-i18next";
import { StatisticData } from "types";
import {
  chargerTypeSelector,
  fetchListChargerType,
} from "redux/slice/chargerTypeSlice";
import {
  chargerProtocolSelector,
  fetchListChargerProtocol,
} from "redux/slice/chargerProtocolSlice";

const { Column } = Table;
const { Title } = Typography;

function Place() {
  const { t, i18n } = useTranslation(["home"]);

  const breadcrumbs: Breadcrumb[] = [
    {
      name: `${t("facility_6.1")}`,
      url: "/places",
    },
  ];
  const lang = i18n.language;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { role } = useAppSelector(selectAuth);
  const { loading, places, pagination, status } =
    useAppSelector(listPlaceSelector);
  const {
    status: statusAction,
    openPlace,
    stopPlace,
  } = useAppSelector(placeSelector);
  const {
    placeStatistic: { data: statistic },
  } = useAppSelector(placeSelector);
  const {
    listChargerType: {
      loading: loadingChargerType,
      chargerType: chargerTypeList,
    },
  } = useAppSelector(chargerTypeSelector);
  const {
    listChargerProtocol: { loading: loadingChargerProtocol, chargerProtocol },
  } = useAppSelector(chargerProtocolSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);

  const prefectureCode = Form.useWatch("state", form);
  const cityCode = Form.useWatch("city", form);
  const placeTypeId = Form.useWatch("place_type_id", form);
  const placeCode = Form.useWatch("uuid", form);
  const address = Form.useWatch("address", form);
  const placeName = Form.useWatch("name", form);

  const prefectureCodeSearch = searchParams.get("state");
  const cityCodeSearch = searchParams.get("city");
  const placeTypeIds = searchParams.getAll("place_type_id").join(",");
  const placeTypeIdModal = searchParams.getAll("place_type_id");
  const placeCodeSearch = searchParams.get("uuid");
  const addressSearch = searchParams.get("address");
  const placeNameSearch = searchParams.get("name");
  const pageSearch = searchParams.get("page") ?? 1;

  const onSearch = () => {
    setVisible(false);
    setSearchParams({
      state: prefectureCode ?? "",
      city: cityCode ?? "",
      place_type_id: placeTypeId ?? "",
      uuid: placeCode ?? "",
      address: address ?? "",
      name: placeName ?? "",
      page: Constant.DEFAULT_PAGE.toString(),
    });
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onClearSearch = () => {
    form.resetFields();
  };

  const onChangePage = (page: number) => {
    setSearchParams({
      state: prefectureCodeSearch ?? "",
      city: cityCodeSearch ?? "",
      place_type_id: placeTypeIds ?? "",
      uuid: placeCodeSearch ?? "",
      address: addressSearch ?? "",
      name: placeNameSearch ?? "",
      page: page.toString(),
    });
  };

  const openModalSearch = () => {
    setVisible(true);
    form.setFields([
      { name: "state", value: prefectureCodeSearch ?? null },
      { name: "uuid", value: placeCodeSearch ?? null },
      { name: "address", value: addressSearch ?? null },
      { name: "name", value: placeNameSearch ?? null },
    ]);
    if (!(placeTypeIdModal.length === 1 && placeTypeIdModal?.[0] === "")) {
      form.setFields([{ name: "place_type_id", value: placeTypeIdModal }]);
    } else {
      form.setFields([{ name: "place_type_id", value: [] }]);
    }
    if (prefectureCodeSearch) {
      form.setFields([{ name: "city", value: cityCodeSearch }]);
    } else {
      form.setFields([{ name: "city", value: [] }]);
    }
  };

  useEffect(() => {
    dispatch(
      fetchListPlace({
        state: prefectureCodeSearch,
        city: cityCodeSearch,
        place_type_id: placeTypeIds,
        uuid: placeCodeSearch,
        address: addressSearch,
        name: placeNameSearch,
        page: Number(pageSearch),
      } as ListPlaceRequest)
    );

    dispatch(
      fetchPlaceStatistic({
        state: prefectureCodeSearch,
        city: cityCodeSearch,
        place_type_id: placeTypeIds,
        uuid: placeCodeSearch,
        address: addressSearch,
        name: placeNameSearch,
      } as StatisticData)
    );
  }, [dispatch, form, searchParams]);

  useEffect(() => {
    // setKeyword({
    //   state: prefectureCodeSearch,
    //   city: cityCodeSearch,
    //   place_type_id: placeTypeIds,
    //   uuid: placeCodeSearch,
    //   address: addressSearch,
    //   name: placeNameSearch,
    //   page: Number(pageSearch)
    // } as ListPlaceRequest);

    form.setFields([
      { name: "state", value: prefectureCodeSearch ?? null },
      { name: "uuid", value: placeCodeSearch ?? null },
      { name: "address", value: addressSearch ?? null },
      { name: "name", value: placeNameSearch ?? null },
    ]);
    if (!(placeTypeIds.length === 1 && placeTypeIds?.[0] === "")) {
      form.setFields([{ name: "place_type_id", value: placeTypeIds }]);
    }
    if (prefectureCodeSearch) {
      form.setFields([{ name: "city", value: cityCodeSearch }]);
    } else {
      form.setFields([{ name: "city", value: [] }]);
    }
  }, [searchParams]);

  const renderDetailPlace = (uuid: string) => {
    const onDetailPlace = () => navigate(`/places/detail/${uuid}`);
    return (
      <Button
        className="text detail-button-place onTab"
        tabIndex={6}
        onClick={onDetailPlace}
      >
        {t("Coupons.詳細")}
      </Button>
    );
  };

  const renderChargerType = (charger_type: string) => {
    let arr = null;
    Object.keys(statistic?.charger_type?.detail).forEach(function (v, i) {
      if (v === charger_type) {
        arr = Object.values(statistic?.charger_type?.detail)[i];
      }
    });
    return <Typography> {arr} </Typography>;
  };
  const renderChargerProtocol = (charger_protocol: string) => {
    let arr = null;
    Object.keys(statistic?.charger_protocol?.detail).forEach(function (v, i) {
      if (v === charger_protocol) {
        arr = Object.values(statistic?.charger_protocol?.detail)[i];
      }
    });

    if (!arr) {
      arr = "0";
    }

    return <Typography> {arr} </Typography>;
  };

  const renderTableActions = (_: any, data: PlaceData) => {
    const onDetailClick = () => {
      var faclity_uuid = window.localStorage.getItem("faclity_uuid");
      if (faclity_uuid !== undefined) {
        window.localStorage.removeItem("faclity_uuid");
      }
      window.localStorage.setItem("faclity_uuid", String(data.uuid));
      navigate(`/chargers/list-chargers/${data.uuid}`, { state: true });
    };

    return (
      <Button
        className="btn detail-charging-equipment onTab"
        tabIndex={8}
        onClick={onDetailClick}
      >
        {`${t("facility_6.50")}`}
      </Button>
    );
  };

  const onAddPlace = () => {
    navigate("/places/create");
  };

  useEffect(() => {
    // let params : StatisticData = {
    //     state : prefectureCodeSearch ?? '',
    // };
    // dispatch(fetchPlaceStatistic(params));
    //dispatch(fetchPlaceType());
    //dispatch(getStateData());
    dispatch(fetchListChargerType({}));
    dispatch(fetchListChargerProtocol({}));
  }, [dispatch]);

  useEffect(() => {
    if (prefectureCode) {
      dispatch(getListCityByPrefectureData({ prefecture: prefectureCode }));
    }
  }, [dispatch, prefectureCode]);

  const renderCount = (_: any, place: PlaceData) => {
    return place?.chargers_uuid.length;
  };

  useRedirectStatusError(status, setStatusPlace());
  useRedirectStatusError(statusAction, resetUpdateState());

  return (
    <Layout className="index-place">
      <Helmet>
        <title>{`${t("facility_6.1")}`}</title>
      </Helmet>
      <Spin
        spinning={
          loading ||
          openPlace.loading ||
          stopPlace.loading ||
          loadingChargerType ||
          loadingChargerProtocol
        }
      >
        <Row justify={"space-between"}>
          <Col>
            <Breadcrumbs breadcrumb={breadcrumbs} />
          </Col>
          <Row>
            <Col>
              <Button
                tabIndex={4}
                htmlType="button"
                className="icon-center-container push-now btn-no-icon onTab"
                onClick={openModalSearch}
                style={{
                  color: "#ffffff",
                  backgroundColor: "#34C2C2",
                  borderColor: "#34C2C2",
                }}
              >
                <Image
                  className="icon-center"
                  height="15px"
                  src={iconFilter}
                  preview={false}
                />
                <span className="text-add">{t("Coupons.フィルター")}</span>
              </Button>
            </Col>

            {isValidRoles(role, [SUPER_ADMIN]) ? (
              <Col>
                <Button
                  tabIndex={5}
                  htmlType="button"
                  className="icon-center-container btn-icon onTab"
                  onClick={onAddPlace}
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#34C2C2",
                    borderColor: "#34C2C2",
                  }}
                >
                  <Image
                    className="icon-center"
                    width="20px"
                    src={placeImg}
                    preview={false}
                  />
                  <span className="text-add ">{`${t("facility_6.3")}`}</span>
                </Button>
              </Col>
            ) : null}
          </Row>
        </Row>

        <Col className="">
          <Row className="mgb-30">
            <Col xs={24} sm={24} md={24} lg={24} xl={22} xxl={24}>
              <Card>
                <Row justify="start" className="text-bold">
                  <Typography className="card-title">{`${t(
                    "facility_6.5"
                  )}`}</Typography>
                </Row>
                <Row gutter={[16, 16]} justify="space-between">
                  <Col xs={24} sm={24} md={7} lg={5} xl={5} xxl={4}>
                    <Row justify={"center"}>
                      <Title
                        style={{
                          fontSize: 64,
                          textAlign: "center",
                          height: "100%",
                        }}
                      >
                        {statistic?.facility_type?.total
                          ? statistic?.facility_type?.total
                          : 0}
                      </Title>
                    </Row>
                  </Col>
                  {/* <Col xs={24} sm={24} md={17} lg={19} xl={19} xxl={20} style={{ marginBottom: '30px' }}>
                                        <Row className='text-bold' style={{ marginLeft: '30px', marginBottom: '20px' }}>
                                            <Typography>{`${t('facility_6.6')}`}</Typography>                     
                                        </Row>
                                        <Row style={{ marginLeft: '30px' }}>
                                            {lang === 'jp' && (statistic?.facility_type?.detail
                                                ? handlePlace(statistic?.facility_type?.detail).map((item: any, i) => {
                                                      return (
                                                          <Col
                                                              key={i}
                                                              style={{ marginRight: '15px' }}
                                                              xs={24}
                                                              sm={24}
                                                              md={9}
                                                              lg={5}
                                                              xl={5}
                                                              xxl={5}
                                                          >
                                                              {Object.keys(item).map(function (keyName: string, keyIndex) {
                                                                  return (
                                                                      <Row
                                                                          justify='space-between'
                                                                          style={{
                                                                              borderTop: '1.8px solid #C3C3C3',
                                                                              lineHeight: '40px',
                                                                              borderBottom:
                                                                                  Object.keys(item).pop() === keyName
                                                                                      ? '1.8px solid #C3C3C3'
                                                                                      : '0px',
                                                                          }}
                                                                      >
                                                                          <Typography className='text-type-place '>{`${t(keyName)}`}</Typography>
                                                                          <Typography>{item[keyName]}</Typography>
                                                                      </Row>
                                                                  );
                                                              })}
                                                          </Col>
                                                      );
                                                  })
                                                : null)}
                                                {lang === 'en' && (statistic?.facility_type?.detail_en
                                                ? handlePlace(statistic?.facility_type?.detail_en).map((item: any, i) => {
                                                      return (
                                                          <Col
                                                              key={i}
                                                              style={{ marginRight: '15px' }}
                                                              xs={24}
                                                              sm={24}
                                                              md={9}
                                                              lg={5}
                                                              xl={5}
                                                              xxl={5}
                                                          >
                                                              {Object.keys(item).map(function (keyName: string, keyIndex) {
                                                                  return (
                                                                      <Row
                                                                          justify='space-between'
                                                                          style={{
                                                                              borderTop: '1.8px solid #C3C3C3',
                                                                              lineHeight: '25px',
                                                                            //   borderBottom:
                                                                            //       Object.keys(item).pop() === keyName
                                                                            //           ? '1.8px solid #C3C3C3'
                                                                            //           : '0px',
                                                                          }}
                                                                      >
                                                                          <Typography className='text-type-place '>{keyName}</Typography>
                                                                          <Typography>{item[keyName]}</Typography>
                                                                      </Row>
                                                                  );
                                                              })}
                                                          </Col>
                                                      );
                                                  })
                                                : null)}
                                        </Row>
                                    </Col> */}
                </Row>
              </Card>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="mgb-30">
              <Card>
                <Row justify="start" className="mt-2 text-bold">
                  <Typography className="card-title">{`${t(
                    "facility.ChargerStatus"
                  )}`}</Typography>
                </Row>
                <Row gutter={[16, 16]} justify="space-between">
                  <Col xs={24} sm={24} md={7} lg={6} xl={9} xxl={9}>
                    <Row className="text-bold ">
                      <Typography>{`${t("facility_6.21")}`}</Typography>
                    </Row>
                    <Row justify={"center"}>
                      <Title
                        style={{ fontSize: 64 }}
                        className="total-number-charger"
                      >
                        {statistic?.charging_status?.total
                          ? statistic?.charging_status?.total
                          : 0}
                      </Title>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={17} lg={18} xl={15} xxl={15}>
                    <Row className="mgl-15 mgb-25 text-bold ">
                      <Typography>{`${t("facility.Statuses")}`}</Typography>
                    </Row>
                    <Row justify="space-between" className="mgl-15 mgb-10">
                      <Col xs={24} sm={9} md={9} lg={9} xl={9} xxl={9}>
                        <Row>
                          <Typography className="booked mgr-8"></Typography>
                          <Typography>{`${t("facility.free")}`}</Typography>
                        </Row>
                      </Col>
                      <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
                        {(statistic?.charging_status?.detail?.["free"]
                          ? statistic?.charging_status?.detail?.["free"]
                          : 0) -
                          (statistic?.charger_status?.detail?.["unavailable"]
                            ? statistic?.charger_status?.detail?.["unavailable"]
                            : 0)}
                      </Col>
                    </Row>

                    <Row className="mgl-15 mgb-10" justify="space-between">
                      <Col xs={24} sm={9} md={9} lg={9} xl={9} xxl={9}>
                        <Row>
                          <Typography className="suspension mgr-8"></Typography>
                          <Typography>{`${t("facility.busy")}`}</Typography>
                        </Row>
                      </Col>
                      <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
                        {statistic?.charging_status?.detail?.["busy"]
                          ? statistic?.charging_status?.detail?.["busy"]
                          : 0}
                      </Col>
                    </Row>

                    <Row className="mgl-15 mgb-10" justify="space-between">
                      <Col xs={24} sm={9} md={9} lg={9} xl={9} xxl={9}>
                        <Row>
                          <Typography className="suspended mgr-8"></Typography>
                          <Typography>{`${t("facility.error")}`}</Typography>
                        </Row>
                      </Col>
                      <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
                        {statistic?.charging_status?.detail?.["error"]
                          ? statistic?.charging_status?.detail?.["error"]
                          : 0}
                      </Col>
                    </Row>
                    <Row className="mgl-15 mgb-10" justify="space-between">
                      <Col xs={24} sm={9} md={9} lg={9} xl={9} xxl={9}>
                        <Row>
                          <Typography className="unavailable mgr-8"></Typography>
                          <Typography>{`${t("Users.利用停止")}`}</Typography>
                        </Row>
                      </Col>
                      <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
                        {statistic?.charger_status?.detail?.["unavailable"]
                          ? statistic?.charger_status?.detail?.["unavailable"]
                          : 0}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col
              xs={10}
              sm={10}
              md={10}
              lg={10}
              xl={10}
              xxl={10}
              className="mgb-30"
            >
              <Card>
                <Row justify="start" className="mt-2 text-bold">
                  <Typography className="card-title">{`${t(
                    "facility_6.32"
                  )}`}</Typography>
                </Row>
                <Row className="mgb-10"></Row>
                <Row className="mgb-10"></Row>
                <Row justify="space-between">
                  {chargerTypeList?.map(({ en_name, name, icon_url }) => {
                    return (
                      <Col xs={12} sm={12} md={9} lg={9} xl={10} xxl={10}>
                        <Row className="mgb-25" justify="space-between">
                          <Col>
                            <Image
                              width="25px"
                              src={icon_url}
                              preview={false}
                            />
                          </Col>
                          <Col xxl={16}>
                            <Typography>
                              {lang === "en" ? en_name : name}
                            </Typography>
                          </Col>
                          <Col sm={2} xxl={3}>
                            {statistic?.charger_type?.detail
                              ? renderChargerType(name)
                              : 0}
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
              </Card>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} className="mgb-30">
              <Card>
                <Row justify="start" className="mt-2 text-bold">
                  <Typography className="card-title">{`${t(
                    "facility_6.55"
                  )}`}</Typography>
                </Row>
                <Row className="mgb-10"></Row>
                <Row className="mgb-10"></Row>
                <Row className="mgb-10"></Row>
                <Row className="mgb-5"></Row>
                <Row justify="space-between" className="mgb-10">
                  {chargerProtocol?.map(({ name }) => {
                    return (
                      <Col xs={12} sm={12} md={9} lg={8} xl={8} xxl={8}>
                        <Row className="mgb-10" justify="space-between">
                          <Col>
                            <Typography>{name}</Typography>
                          </Col>
                          <Col sm={2} xxl={5}>
                            {statistic?.charger_protocol?.detail
                              ? renderChargerProtocol(name)
                              : 0}
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
        <Card className="mgt-5">
          {/* <Row justify={'space-between'}>
                        <Col>
                        </Col>
                        <Row className="mt-4">
                            <Col>
                                <Button
                                    htmlType='button'
                                    className='icon-center-container push-now btn-no-icon'
                                    onClick={openModalSearch}
                                    style={{ color: '#ffffff', backgroundColor: '#34C2C2', borderColor: '#34C2C2' }}
                                >
                                    <span className='text-btn '>{t('facility_6.2')}</span>
                                </Button>
                            </Col>
                        </Row>
                    </Row> */}
          <Table
            dataSource={places}
            className={
              pagination?.total > pagination?.perPage
                ? "text-center mt-4 mb-1"
                : "text-center mt-4 mb-4"
            }
            pagination={
              pagination?.total > pagination?.perPage
                ? {
                    total: pagination?.total,
                    current: Number(pageSearch),
                    pageSize: pagination?.perPage,
                    position: ["bottomRight"],
                    onChange: onChangePage,
                    showSizeChanger: false,
                  }
                : false
            }
            scroll={{ x: 960 }}
            rowKey="id"
          >
            <Column
              width="1%"
              title="No."
              key="index"
              render={(value, item, index) =>
                (Number(pageSearch) - 1) * 10 + 1 + index
              }
            />
            <Column
              title={`${t("facility_6.41")}`}
              dataIndex="name"
              width="15%"
            />
            {/* <Column title={`${t('facility.UUID')}`} dataIndex='uuid' key='uuid' width='5%' /> */}
            <Column
              title={`${t("facility_6.57")}`}
              dataIndex="chargers_uuid"
              key="chargers_uuid"
              render={renderCount}
              width="6%"
            />
            <Column
              title={t("Coupons.詳細")}
              dataIndex="uuid"
              key="uuid"
              width="4%"
              render={renderDetailPlace}
            />
            <Column
              title={`${t("facility_6.44")}`}
              dataIndex="uuid"
              key="uuid"
              render={renderTableActions}
              width="4%"
            />
          </Table>
        </Card>
        <Modal
          centered
          visible={visible}
          footer={null}
          width={700}
          destroyOnClose={true}
          onCancel={onCancel}
        >
          <Form
            className="mt-0"
            labelWrap
            form={form}
            labelCol={{ span: 24 }}
            requiredMark={true}
            onFinish={onSearch}
            colon={false}
          >
            {/* <Row>
                            <Col span={11}>
                                <Form.Item name='state' label={`${t('facility_filter_7.1')}`}>
                                    <Select 
                                        dropdownStyle={{ zIndex: 2000 }} 
                                        showArrow
                                        showSearch
                                        filterOption={(inputValue, option: any) =>
                                            option.props.children
                                            .toString()
                                            .toLowerCase()
                                            .includes(inputValue.toLowerCase())
                                        }
                                    >
                                        {prefecture?.data?.map(({ name }) => {
                                            return (
                                                <Option key={name} value={name}>
                                                    {name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11} offset={2}>
                                <Form.Item name='city' label={`${t('facility_filter_7.2')}`}>
                                    <Select 
                                        disabled={!prefectureCode} 
                                        dropdownStyle={{ zIndex: 2000 }} 
                                        showArrow
                                        showSearch
                                        filterOption={(inputValue, option: any) =>
                                            option.props.children
                                            .toString()
                                            .toLowerCase()
                                            .includes(inputValue.toLowerCase())
                                        }
                                    >
                                        {cityByPrefecture?.data?.map((data, index) => (
                                            <Option key={`city-${index}`} value={data?.name}>
                                                {data?.name}{' '}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row> */}
            {/* <Row>
                            <Col span={11}>
                                <Form.Item name='place_type_id' label={`${t('facility_filter_7.3')}`}>
                                    <Select 
                                        dropdownStyle={{ zIndex: 2000 }} 
                                        mode='multiple' 
                                        showArrow
                                        showSearch
                                        filterOption={(inputValue, option: any) =>
                                            option.props.children
                                            .toString()
                                            .toLowerCase()
                                            .includes(inputValue.toLowerCase())
                                        }
                                    >
                                        {placeType.map(({ uuid, name, en_name }) => {
                                            return (
                                                <Option key={`place_type_id_${uuid}`} value={uuid.toString()}>
                                                    {
                                                            i18n.language === 'en' ? en_name : name
                                                     }
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11} offset={2}>
                                <Form.Item name='uuid' label={`${t('facility_filter_7.7')}`}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        
                        <Form.Item name='address' label={`${t('facility_filter_7.8')}`}>
                            <Input />
                        </Form.Item> */}

            <Form.Item name="name" label={`${t("facility_filter_7.9")}`}>
              <Input />
            </Form.Item>

            <Row justify="center">
              <Col span={10}>
                <Row justify="space-between">
                  <Button
                    onClick={onCancel}
                    className="icon-center-container "
                    style={{
                      width: "30%",
                      color: "red",
                      justifyContent: "center",
                      backgroundColor: "#FFF1F0",
                      borderColor: "red",
                      paddingRight: "30px",
                      paddingLeft: "30px",
                    }}
                  >
                    {`${t("facility_filter_7.10")}`}
                  </Button>
                  <Button
                    onClick={onClearSearch}
                    className="icon-center-container"
                    style={{
                      width: "30%",
                      color: "#2F9B4B",
                      justifyContent: "center",
                      backgroundColor: "#ffffff",
                      borderColor: "#34c2c2",
                    }}
                  >
                    {`${t("facility_filter_7.12")}`}
                  </Button>
                  <Button
                    htmlType="submit"
                    className="icon-center-container btn-button-modal"
                    style={{
                      width: "30%",
                      color: "#2F9B4B",
                      justifyContent: "center",
                      backgroundColor: "#E0F4E4",
                      borderColor: "#2F9B4B",
                    }}
                  >
                    {`${t("facility_filter_7.11")}`}
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Spin>
    </Layout>
  );
}

export default Place;
